<template>
  <div>
    <Device 
      :device_guid="this.$route.params.id" 
      :device.sync="device" 
      @update="onUpdated"
      @reset="onResetSensor"/>
  </div>
</template>

<script>
import Device from '@/views/thcs/Device'
import DeviceManage from '@/views/device/DeviceManage'
export default {
  name: 'THCSDeviceManage',
  extends: DeviceManage,
  components: {
    Device,
  }
}
</script>