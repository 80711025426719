<template>
<div>
  <CCard>
    <CCardHeader>
      <h4 style="color:#00A8FF">{{ device.name }}({{ serviceModel }})</h4>
    </CCardHeader>

    <CCardBody>
        <CCol col="12" xl="12">
          <ul class="nav nav-tabs nav-pills">
            <li class="nav-item">
              <a tab class="nav-link" @click.prevent="setActive('tab3')" :class="{ active: isActive('tab3') }" href='#tab3'>제어반 상태</a>
            </li>        
            <!--<li class="nav-item">
              <a tab class="nav-link" @click.prevent="setActive('tab4')" :class="{ active: isActive('tab4') }" href='#tab4'>이벤트 사진</a>
            </li>-->
            <li class="nav-item ">
              <a tab class="nav-link" @click.prevent="setActive('tab5')" :class="{ active: isActive('tab5') }" href='#tab5'>그래프 보기</a>
            </li>
            <li class="nav-item">
              <a tab class="nav-link" @click.prevent="setActive('tab6')" :class="{ active: isActive('tab6') }" href='#tab6'>작업 일지</a>
            </li>        
            <li class="nav-item">
              <a tab class="nav-link" @click.prevent="setActive('tab7')" :class="{ active: isActive('tab7') }" href='#tab7'>기기 정보</a>
            </li>                
          </ul>
        </CCol>   
     <div class="tab-content">
        <div class="tab-pane" id='tab3' :class="{ 'active show': isActive('tab3') }">
          <CCard>
            <CCardBody>
              <h5 style="margin-top: 20px; text-align: right;">{{measureDate}}</h5>
      
              <div class="deviceInfoBox" style="margin-top: -16px;">
                <div class="btnBox">
                  <button disabled="disabled" style="cursor: default">{{device_type.in}}</button>
                </div>
                <table>
                  <tr>
                    <th>유량</th>
                    <td>{{flowRateIn}}</td>
                  </tr>
                  <tr>
                    <th>THC</th>
                    <td>{{THCSIn}}</td>
                  </tr>
                </table>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <div class="btnBox">
                  <button disabled="disabled" style="cursor: default">{{device_type.out}}</button>
                </div>
                <table>
                  <tr>
                    <th>유량</th>
                    <td>{{flowRateOut}}</td>
                  </tr>
                  <tr>
                    <th>THC</th>
                    <td>{{THCSOut}}</td>
                  </tr>
                </table>
              </div>
      
              <div class="deviceSlideBox">
                <img :src="device_image" alt="기기 이미지" style="max-width: 100%; height: 100%;">
              </div>

              <div class="deviceInfoBox">
                <div class="btnBox">
                  <button disabled="disabled" style="cursor: default">연소 배출부</button>
                </div>
                <table>
                  <tr>
                    <th>THC</th>
                    <td>{{THCSDischarge}}</td>
                  </tr>
                  <tr>
                    <th>NOx</th>
                    <td>{{NOxOut}}</td>
                  </tr>
                  <tr>
                    <th>SOx</th>
                    <td>{{SOxOut}}</td>
                  </tr>
                  <tr>
                    <th>CO</th>
                    <td>{{COOut}}</td>
                  </tr>
                  <tr>
                    <th>CO2</th>
                    <td>{{CO2Out}}</td>
                  </tr>                  
                </table>
              </div>

              <div class="deviceInfoBox">
                <div class="btnBox">
                  <button disabled="disabled" style="cursor: default">연소 유입부</button>
                </div>
                <table>
                  <tr>
                    <th>유량</th>
                    <td>{{flowRateDischarge}}</td>
                  </tr>
                  <tr>
                    <th>THC</th>
                    <td>{{THCSCombustion}}</td>
                  </tr>
                  <tr>
                    <th>온도</th>
                    <td>{{temp_value[15]}}(℃)</td>
                  </tr>                                         
                </table>
             </div>
            </CCardBody>
            <div style="text-align: center;">
              <div class="deviceInfoBox" style="float: none; display: inline-block;">
                    <div class="btnBox">
                      <button disabled="disabled" style="cursor: default">1번 흡착탑</button>
                    </div>
                    <table>
                      <tr>
                        <th>유입부 기압</th>
                        <td>{{press_value[0]}}(hPa)</td>
                      </tr>
                      <tr>
                        <th>유입부 온도</th>
                        <td>{{temp_value[0]}}(℃)</td>
                      </tr>
                      <tr>
                        <th>상단부 온도</th>
                        <td>{{temp_value[1]}}(℃)</td>
                      </tr>                                         
                      <tr>
                        <th>중간부 온도</th>
                        <td>{{temp_value[2]}}(℃)</td>
                      </tr>     
                      <tr>
                        <th>하단부 온도</th>
                        <td>{{temp_value[3]}}(℃)</td>
                      </tr>
                      <tr>
                        <th>유출부 기압</th>
                        <td>{{press_value[1]}}(hPa)</td>
                      </tr>
                      <tr>
                        <th>유출부 온도</th>
                        <td>{{temp_value[4]}}(℃)</td>
                      </tr>                                                                             
                    </table>
                </div>
                <div class="deviceInfoBox" style="float: none; display: inline-block;">
                    <div class="btnBox">
                      <button disabled="disabled" style="cursor: default">2번 흡착탑</button>
                    </div>
                    <table>
                      <tr>
                        <th>유입부 기압</th>
                        <td>{{press_value[2]}}(hPa)</td>
                      </tr>
                      <tr>
                        <th>유입부 온도</th>
                        <td>{{temp_value[5]}}(℃)</td>
                      </tr>
                      <tr>
                        <th>상단부 온도</th>
                        <td>{{temp_value[6]}}(℃)</td>
                      </tr>                                         
                      <tr>
                        <th>중간부 온도</th>
                        <td>{{temp_value[7]}}(℃)</td>
                      </tr>     
                      <tr>
                        <th>하단부 온도</th>
                        <td>{{temp_value[8]}}(℃)</td>
                      </tr>
                      <tr>
                        <th>유출부 기압</th>
                        <td>{{press_value[3]}}(hPa)</td>
                      </tr>
                      <tr>
                        <th>유출부 온도</th>
                        <td>{{temp_value[9]}}(℃)</td>
                      </tr>                                        
                    </table>
                </div>
                <div class="deviceInfoBox" style="float: none; display: inline-block;">
                    <div class="btnBox">
                      <button disabled="disabled" style="cursor: default">3번 흡착탑</button>
                    </div>
                    <table>
                      <tr>
                        <th>유입부 기압</th>
                        <td>{{press_value[4]}}(hPa)</td>
                      </tr>
                      <tr>
                        <th>유입부 온도</th>
                        <td>{{temp_value[10]}}(℃)</td>
                      </tr>
                      <tr>
                        <th>상단부 온도</th>
                        <td>{{temp_value[11]}}(℃)</td>
                      </tr>                                         
                      <tr>
                        <th>중간부 온도</th>
                        <td>{{temp_value[12]}}(℃)</td>
                      </tr>     
                      <tr>
                        <th>하단부 온도</th>
                        <td>{{temp_value[13]}}(℃)</td>
                      </tr>
                      <tr>
                        <th>유출부 기압</th>
                        <td>{{press_value[5]}}(hPa)</td>
                      </tr>
                      <tr>
                        <th>유출부 온도</th>
                        <td>{{temp_value[14]}}(℃)</td>
                      </tr>                                        
                    </table>
                </div>
              </div>
            <CCardFooter col="12" xl="12">
              <CButton color="danger" class="float-right" v-show="is_admin == true" @click="resetSensorBoard()">센서보드 리셋</CButton>
              <CButton color="success" class="float-right mr-1"  @click="openSettings('semi')">원격 제어</CButton>
              <CButton color="warning" class="float-right mr-1" @click="openDialog('sensor')">센서 정보 관리</CButton>
            </CCardFooter>
          </CCard>
        </div>
        <div class="tab-pane" id='tab5' :class="{ 'active show': isActive('tab5') }">
          <HistoryMeasure
              ref="historyMeasure"
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
        </div>
        <div class="tab-pane" id='tab6' :class="{ 'active show': isActive('tab6') }">
          <HistoryDevice
              ref=historyDevice
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
        </div>
        <div class="tab-pane" id='tab7' :class="{ 'active show': isActive('tab7') }">
          <Productinfo
              ref=productinfo
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
        </div>
      </div>      
    </CCardBody>
  </CCard>

  <Confirm
      ref="confirmDialog"
      title="확인"
      @hide="hideModal"
      color="warning"
  />
  <DeviceInfo
      ref="deviceInfo"
      :isAdmin="is_admin"
      :isMobile="is_mobile"
      :isPeriodic="false"
      :device.sync="device"
      @hide="hideModal"
      @update="onUpdated"
  />

  <RemoteDialog
    ref="remoteDialog"
  />
</div>
</template>

<script>

import Device from '@/views/btfs/Device'
import DeviceInfo from '@/views/device/DeviceInfo'
import RemoteDialog from '@/views/thcs/RemoteDialog';

import Event from '@/views/device/Event'
import Search from '@/views/device/Search';
import Productinfo from '@/views/watek/Productinfo';

import HistoryMeasure from '@/views/device/HistoryMeasure';
import HistoryDevice from '@/views/device/HistoryDevice';

const NO_MEASURE_COMENT = '측정값 없음'
const RUNNING_STATUS = 30;
const CO2_VALUE = 16;
const FLOW_RATE = 24;
const NOX_SO2_VALUE = 28;
const CO_VALUE = 31;
const TEMP_VALUE = 35;
const PRESS_VALUE = 36;
const THCS_VALUE = 37;

export default {
  name: 'THCSDevice',
  extends: Device,
  components: {
    RemoteDialog,
    DeviceInfo,
    Event,
    Search,
    Productinfo,
    HistoryMeasure,
    HistoryDevice
  },
  data () {
    return {
      activeItem: 'tab3',
      temp_value: [],
      press_value: []
    }
  },
  computed: {
    runningWaterInValveStatus(){
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT      
      else
        return this.getValue(RUNNING_STATUS, 1)
    },
    runningSuctionValveStatus(){
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT      
      else
        return this.getValue(RUNNING_STATUS, 2)
    },
    runningPumpStatus(){
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT      
      else
        return this.getValue(RUNNING_STATUS, 3)
    },
    runningMeterPumpStatus(){
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT      
      else
        return this.getValue(RUNNING_STATUS, 4)
    },
    flowRateIn() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(FLOW_RATE, 1)
    },
    flowRateOut() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(FLOW_RATE, 2)
    }, 
    flowRateDischarge() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(FLOW_RATE, 3)
    },         
    THCSIn() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(THCS_VALUE, 1)
    },
    THCSOut() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(THCS_VALUE, 2)
    }, 
    THCSCombustion() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(THCS_VALUE, 3)
    },     
    THCSDischarge() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(THCS_VALUE, 4)
    },      
    NOxOut() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(NOX_SO2_VALUE, 2)
    },
    SOxOut() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(NOX_SO2_VALUE, 1)
    },    
    COOut() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(CO_VALUE, 1)
    },
    CO2Out() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(CO2_VALUE, 1)
    }, 
    CO2Out() {
      if (_.isUndefined(this.device.sensor))
        return NO_MEASURE_COMENT
      else
        return this.getValue(CO2_VALUE, 1)
    },     
  },
  methods: {
    getDataControl() {
      var self = this;
      var service_type = _.get(this.device, "service_type"); // || submerged
      switch (service_type) {
        case 'watek': this.serviceModel = '염소투입기'; break;
        default: this.serviceModel = 'THC처리기'
      }

      if (service_type == 'watek') {
        this.device_image = 'images/gas_flow.png';
      } else {
        this.device_image = 'images/gas_flow.png';
      }
      this.parseArrayValue(PRESS_VALUE, 1);
      this.parseArrayValue(TEMP_VALUE, 1);
    },
  	isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    getDates() {
      if (this.activeItem === 'tab5')
        return this.$refs.historyMeasure.getDates();
      else
        return this.$refs.historyDevice.getDates();
    },   
    openSettings(which) {
      console.log('RemoteControl::openSettings which:', which)
      this.$refs.remoteDialog.show(which, this.device);
    },
    getValue(type, id) {
      var item = _.find(this.device.sensor.data, {type:type, id:id })
      if (item) {
        var stype = _.find(this.sensor_type, {code:item.type, id:item.id})
        if (!stype) stype = _.find(this.sensor_type, {code:item.type})
        if (stype) {
          if(item.type == RUNNING_STATUS){
            if(item.id == 1)
              return item.value >= 1 ? "열림" : "닫힘";
            else if(item.id == 2)
              return item.value >= 1 ? "열림" : "닫힘";              
            else if(item.id == 3)
              return item.value >= 1 ? "가동중" : "정지";     
            else if(item.id == 4)
              return item.value >= 1 ? "가동중" : "정지";                            
          }          
          return item.value.toFixed(2) + ' ' + stype.unit
        } else {
          return NO_MEASURE_COMENT
        }
      } else {
        return NO_MEASURE_COMENT
      }
    },    
    parseArrayValue(type, id){
      var item = _.find(this.device.sensor.data, {type:type, id:id })
      if (item) {
        if (_.isArray(item.value)) {
          if(item.type == PRESS_VALUE){
            this.press_value = item.value;
          }          
          if(item.type == TEMP_VALUE){
            this.temp_value = item.value;
          }          
          return '';
        } else {
          return NO_MEASURE_COMENT
        }
      } else {
        return NO_MEASURE_COMENT
      }
    }
  }
}
</script>
<!--
<style scoped>
.user-wrap {
  position: relative;
  text-align: center;
}
.user-text {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 100%;
  transform: translate(-90%, -350%);
  font-size: 20px;
  text-align: center;
}


</style>
-->