const REMOTE_CONTROL_TABLE_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
    };
    },
    formatNoMatches: function() {
        return '-';
    },
    cache: false,
    striped: false,
    bordered: false,
    pagination: false,
    search: false,
    showColumns: false,
    showRefresh: false,
    minimumCountColumns: 2,
    clickToSelect: false,
    singleSelect: false,
    showToggle: false
}

const REMOTE_CONTROL_TABLE_COLUMS = [
    {
        field: 'item',
        title: '항목',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'time',
        title: '시간(초)',
        align: 'center',
        valign: 'top',
        sortable: false
    }, {
        field: 'enable',
        title: '설정',
        align: 'center',
        valign: 'top',
        sortable: false,
    }
]

const REMOTE_CONTROL_TYPE = {
    'thcs' : [
        {
            item: '1번흡착탑 THC 유입 밸브',
            field: 'thcvalve11',
            time: 20,
            enable: false
        }, {
            item: '1번흡착탑 THC 유출 밸브',
            field: 'thcvalve12',
            time: 20,
            enable: false
        }, {
            item: '1번흡착탑 가스 유입 밸브',
            field: 'gasvalve11',
            time: 10,
            enable: false
        }, {
            item: '1번흡착탑 가스 유출 밸브',
            field: 'gasvalve12',
            time: 10,
            enable: false
        },
        {
            item: '2번흡착탑 THC 유입 밸브',
            field: 'thcvalve21',
            time: 20,
            enable: false
        }, {
            item: '2번흡착탑 THC 유출 밸브',
            field: 'thcvalve22',
            time: 20,
            enable: false
        }, {
            item: '2번흡착탑 가스 유입 밸브',
            field: 'gasvalve21',
            time: 10,
            enable: false
        }, {
            item: '2번흡착탑 가스 유출 밸브',
            field: 'gasvalve22',
            time: 10,
            enable: false
        },
        {
            item: '3번흡착탑 THC 유입 밸브',
            field: 'thcvalve31',
            time: 10,
            enable: false
        }, {
            item: '3번흡착탑 THC 유출 밸브',
            field: 'thcvalve32',
            time: 10,
            enable: false
        }, {
            item: '3번흡착탑 가스 유입 밸브',
            field: 'gasvalve31',
            time: 10,
            enable: false
        }, {
            item: '3번흡착탑 가스 유출 밸브',
            field: 'gasvalve32',
            time: 10,
            enable: false
        }
    ]
}

export {
    REMOTE_CONTROL_TABLE_OPTIOINS,
    REMOTE_CONTROL_TABLE_COLUMS,
    REMOTE_CONTROL_TYPE
}